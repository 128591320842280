import { useState, useEffect, useCallback } from 'react';
import { requestServerApi, serverApi } from '../../api/api';
import { AuthState } from '@aws-amplify/ui-components';

const useMaintenanceAccess = (userState: any) => {
  const [isMaintainer, setIsMaintainer] = useState<boolean | null>(null);
  const [isMaintenanceActive, setIsMaintenanceActive] = useState<boolean | null>(null);
  const [isLoadingState, setIsLoadingState] = useState(true);

  const fetchMaintenanceAccess = useCallback(async (organizationKey: string) => {
    let isActive = true;

    try {
      const apiResponse = await requestServerApi<any>(() =>
        serverApi.mobileAppControllerGetMaintenanceAccess(organizationKey)
      );
      const maintenanceData = apiResponse?.data?.data?.[0] || {};

      if (isActive) { 
        setIsMaintainer(maintenanceData.isMaintainer ?? false);
        setIsMaintenanceActive(maintenanceData.isMaintenanceActive ?? false);
      }
    } catch (e) {
      console.error("メンテナンス情報の取得に失敗", e);
      if (isActive) {
        setIsMaintainer(false);
        setIsMaintenanceActive(false);
      }
    } finally {
      if (isActive) {
        setIsLoadingState(false);
      }
    }

    return () => {
      isActive = false;
    };
  }, []);

  useEffect(() => {
    if (userState?.authState === AuthState.SignedIn && userState?.user) {
      const organizationKey = userState?.user["custom:organizationKey"];
      if (organizationKey) {
        fetchMaintenanceAccess(organizationKey);
      }
    } else {
      setIsLoadingState(false);
    }
  }, [userState, fetchMaintenanceAccess]);

  return { isMaintainer, isMaintenanceActive, isLoadingState };
};

export default useMaintenanceAccess;
